<p class="post-content-p" #text [class.showMore]="showMoreContent">
  <ng-container *ngFor="let word of parsedContent">
    <span *ngIf="!isHashtag(word)" [innerHtml]="word + ' '"></span>
    <span class="tag" *ngIf="isHashtag(word)">
      <a
        [routerLink]="['/search']"
        [queryParams]="{ query: word.replace('#', '') }"
      >
        {{ word }}
      </a>
    </span>
  </ng-container>
</p>
<button
  mat-button
  class="show-more"
  *ngIf="text && text.clientHeight < text.scrollHeight"
  (click)="showMore()"
>
  <span *ngIf="!showMoreContent">Devamını Oku</span>
  <span *ngIf="showMoreContent">Gizle</span>
</button>