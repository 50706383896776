import { AfterContentChecked, ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'post-content',
  templateUrl: './post-content.component.html',
  styleUrls: ['./post-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostContentComponent implements OnInit, AfterContentChecked {
  @Input('content') content: string;
  public showMoreContent = false;
  

  public parsedContent: string[] = [];
  public getParsedContent() {
    // check content includes html
    const html = this.content.includes('<');
    if (html) {
      return [this.content];
    } else {
      return this.content.split(' ');
    }
  }

  public showMore() {
    this.showMoreContent = !this.showMoreContent;
  }

  public isHashtag(word) {
    return word.startsWith('#');
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.parsedContent = this.getParsedContent();
    
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
