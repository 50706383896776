import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { eventColors } from '@common/core/utils/event-colors';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-header',
  templateUrl: './event-profile-header.component.html',
  styleUrls: ['./event-profile-header.component.scss', '../panel.scss'],
})
export class EventProfileHeaderComponent implements OnInit {
  @Input() event: Event;

  public canEdit = false;

  constructor(public auth: AuthService) {}

  public get colors() {
    return eventColors.find((color) => color.value === this.event.themeColor);
  }
  public isJoined: boolean = false;
  ngOnInit(): void {
    this.isJoined = this.event.tickets.some(
      (ticket) => this.getUserIdFromModel(ticket.user) === this.auth.user.id
    );

    this.canEdit = this.event.organizatorId == this.auth.selectedProfileId;
  }

  public getUserIdFromModel(user: any) {
    if (user?.id) {
      return user.id;
    }
    return user;
  }
}
