import { UploadService } from '@common/core/upload/upload.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventParticipant } from 'src/app/models/relations/events/event-participants';
import { Members } from 'src/app/models/relations/members';

@Component({
  selector: 'add-project-timeline',
  templateUrl: './add-project-timeline.component.html',
  styleUrls: ['./add-project-timeline.component.scss']
})
export class AddProjectTimelineComponent {
  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { section?: any },
    public uploadService: UploadService
  ) {}

  public formGroup = this.fb.group({
    id: [''],
    name: [''],
    description: [''],
    date: [''],
    images: this.fb.array([]),
  });

  public get images() {
    return this.formGroup.get('images') as FormArray;
  }

  public get result() {
    const data = this.formGroup.value;
    if (!data.id || data.id === '') {
      delete data.id;
    }
    return data;
  }

  ngOnInit(): void {
    if (this.data.section) {
      console.log(this.data.section);
      const { participants, ...data } = this.data.section;
      this.formGroup.patchValue(data);
      for (let image of this.data.section.images) {
        this.images.push(this.fb.group(image));
      }
    }
  }

  public addImage() {
    this.uploadService.openUploadDialog('image',true,(result) => {
      for (let file of result) {
        this.images.push(this.fb.group(file));
      }
    })
  }
}
