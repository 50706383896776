import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-applications',
  templateUrl: './event-profile-applications.component.html',
  styleUrls: ['./event-profile-applications.component.scss','../panel.scss']
})
export class EventProfileApplicationsComponent implements OnInit{
  @Input() public event: Event;
  public hasApplication = false;

  constructor(
    private auth:AuthService
  ) {}

  ngOnInit(): void {
    this.hasApplication = this.event.tickets?.length > 0;
  }
}
